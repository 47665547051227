import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@mui/material';
import DropDown from '../DropDown';
import { getTempWorkerTypes, createDailyTempWorker } from '../../services/apiSLTP/planning';
import { DataItem, updateDailyTempWorker } from '../../services/apiSLTP/activities';
import ModalBody from './ModalBody';
import { useTranslation } from 'react-i18next';
interface ModalProps {
    open: boolean;
    handleClose: () => void;
    activity: DataItem;
    activityIndex: number;
    refetch: () => void;
    tempWorkerId: string;
}

const CreateDailyTempWorkerModal: React.FC<ModalProps> = ({ open, handleClose, activity, activityIndex, refetch, tempWorkerId }) => {
    const [tempWorkersTypes, setTempWorkersTypes] = useState<{ label: string; value: string }[]>([]);
    const [selectedTempWorkerType, setSelectedTempWorkerType] = useState({ label: '', value: '' });
    const [currentTempWorkerId, setCurrentTempWorkerId] = useState(tempWorkerId);
    const { t } = useTranslation();

    const fetchTempWorkersTypes = async () => {
        const response = await getTempWorkerTypes();
        setTempWorkersTypes(
            response.data.map((value) => {
                return { label: value.designation, value: value._id };
            })
        );
    };

    const postDailyTempWorker = async () => {
        const response = await createDailyTempWorker(activity._id, selectedTempWorkerType.value, 1);
        setCurrentTempWorkerId(response.data._id);
        refetch();
    };

    const patchDailyTempWorker = async () => {
        const response = await updateDailyTempWorker(currentTempWorkerId, {
            tempWorkerTypeId: selectedTempWorkerType.value,
        });
        refetch();
    };

    useEffect(() => {
        fetchTempWorkersTypes();

        const dailyTempWorker = activity.dailyTempWorkers.find((value) => value._id === tempWorkerId);
        if (dailyTempWorker) {
            setSelectedTempWorkerType({ label: dailyTempWorker.tempWorkerTypeId?.designation || '', value: dailyTempWorker.tempWorkerTypeId?._id || '' });
        }
    }, []);

    return (
        <ModalBody
            closeText={t('cancel')}
            title={!!currentTempWorkerId.length ? t('modifyTempWorker') : t('addTempWorker')}
            okText={!!currentTempWorkerId.length ? t('update') : t('create')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                !currentTempWorkerId.length ? postDailyTempWorker() : patchDailyTempWorker();
            }}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <DropDown
                    selectedItem={selectedTempWorkerType}
                    items={tempWorkersTypes}
                    handleChange={(event, value) => setSelectedTempWorkerType({ value: value?.value || '', label: value?.label || '' })}
                    inputLabel={t('tempWorker')}
                />
                {!!selectedTempWorkerType.value.length && <></>}
            </div>
        </ModalBody>
    );
};

export default CreateDailyTempWorkerModal;
