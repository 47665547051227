import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import WarningIcon from '@mui/icons-material/Warning';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import OutputIcon from '@mui/icons-material/Output';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import PaletteIcon from '@mui/icons-material/Palette';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';

interface IconProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    classes?: { iconButton?: string; icon?: string };
    title?: string;
    name:
        | 'addCircle'
        | 'deleteForever'
        | 'calendarMonth'
        | 'settingsBackupRestore'
        | 'arrowForward'
        | 'arrowBack'
        | 'sendToMobile'
        | 'warning'
        | 'eventRepeat'
        | 'accessTime'
        | 'edit'
        | 'delete'
        | 'menu'
        | 'visibility'
        | 'visibilityOff'
        | 'fileDownload'
        | 'logout'
        | 'lock'
        | 'output'
        | 'close'
        | 'help'
        | 'peopleAlt'
        | 'precisionManufacturing'
        | 'phonelinkErase'
        | 'resetTv'
        | 'addToHomeScreen'
        | 'verticalAlignTop'
        | 'verticalAlignBottom'
        | 'palette'
        | 'starSharp'
        | 'arrowLeftSharp'
        | 'arrowRightSharp'
        | 'browerUpdate';
    color?: string;
    disabled?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
    dark?: boolean;
    type?: 'button' | 'icon';
}

const Icon: React.FC<IconProps> = ({
    onClick = () => {},
    classes = {},
    title = '',
    name,
    color = '',
    disabled = false,
    onKeyDown = () => {},
    dark = false,
    type = 'button',
}) => {
    let Icon;

    switch (name) {
        case 'addCircle':
            Icon = AddCircleIcon;
            break;
        case 'deleteForever':
            Icon = DeleteForeverIcon;
            break;
        case 'calendarMonth':
            Icon = CalendarMonthIcon;
            break;
        case 'settingsBackupRestore':
            Icon = SettingsBackupRestoreIcon;
            break;
        case 'arrowForward':
            Icon = ArrowForwardIcon;
            break;
        case 'arrowBack':
            Icon = ArrowBackIcon;
            break;
        case 'sendToMobile':
            Icon = SendToMobileIcon;
            break;
        case 'warning':
            Icon = WarningIcon;
            break;
        case 'eventRepeat':
            Icon = EventRepeatIcon;
            break;
        case 'accessTime':
            Icon = AccessTimeIcon;
            break;
        case 'edit':
            Icon = EditIcon;
            break;
        case 'delete':
            Icon = DeleteIcon;
            break;
        case 'menu':
            Icon = MenuIcon;
            break;
        case 'visibility':
            Icon = VisibilityIcon;
            break;
        case 'visibilityOff':
            Icon = VisibilityOffIcon;
            break;
        case 'fileDownload':
            Icon = FileDownloadIcon;
            break;
        case 'logout':
            Icon = LogoutIcon;
            break;
        case 'lock':
            Icon = LockIcon;
            break;
        case 'close':
            Icon = CloseIcon;
            break;
        case 'output':
            Icon = OutputIcon;
            break;
        case 'peopleAlt':
            Icon = PeopleAltIcon;
            break;
        case 'precisionManufacturing':
            Icon = PrecisionManufacturingIcon;
            break;
        case 'phonelinkErase':
            Icon = PhonelinkEraseIcon;
            break;
        case 'browerUpdate':
            Icon = BrowserUpdatedIcon;
            break;
        case 'resetTv':
            Icon = ResetTvIcon;
            break;
        case 'addToHomeScreen':
            Icon = AddToHomeScreenIcon;
            break;
        case 'verticalAlignTop':
            Icon = VerticalAlignTopIcon;
            break;
        case 'verticalAlignBottom':
            Icon = VerticalAlignBottomIcon;
            break;
        case 'palette':
            Icon = PaletteIcon;
            break;
        case 'starSharp':
            Icon = StarSharpIcon;
            break;
        case 'arrowLeftSharp':
            Icon = ArrowLeftSharpIcon;
            break;
        case 'arrowRightSharp':
            Icon = ArrowRightSharpIcon;
            break;
        case 'help':
        default:
            Icon = HelpIcon;
    }

    const iconColor = disabled ? (dark ? 'gray' : 'lightgray') : color || (dark ? 'white' : 'black');
    const hoverColor = disabled ? (dark ? 'gray' : 'lightgray') : dark ? '#b3b3b3' : '#333';

    return (
        <IconButton
            onClick={onClick}
            className={'pad-0-imp ' + classes?.iconButton}
            title={title}
            disabled={disabled}
            onKeyDown={onKeyDown}
            style={{
                color: iconColor,
            }}
        >
            <Icon
                fontSize="large"
                classes={{ root: 'h-25px-imp w-25px-imp ' + classes.icon }}
                style={{
                    transition: 'color 0.3s',
                }}
                onMouseEnter={(e) => {
                    if (!disabled) e.currentTarget.style.color = hoverColor;
                }}
                onMouseLeave={(e) => {
                    if (!disabled) e.currentTarget.style.color = iconColor;
                }}
            />
        </IconButton>
    );
};

export default Icon;
