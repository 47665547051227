import React from 'react';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import { DailyTruckRide } from '../../services/apiSLTP/dailyReports/dailyTrucksRides';
import { DataItem } from '../../services/apiSLTP/activities';
import { getMaterialStateInfos, getTruckModeInfos, permissionLevels, truckRideActivityTypeId } from '../../utils/enums';
import { getMaterialStateColor } from '../../utils/colors';

interface TrucksRidesTableProps {
    dailyTrucksRides: DailyTruckRide[];
    activities: DataItem[];
    universalFilter: string;
}

const TrucksRidesDriversTable: React.FC<TrucksRidesTableProps> = ({ dailyTrucksRides, activities, universalFilter }) => {
    const { t } = useTranslation();

    const activitiesMap = new Map(activities.map((activity) => [activity._id, activity]));

    const sortedDailyTrucksRides = () => {
        return dailyTrucksRides
            .filter((value) => {
                return activitiesMap.get(value.activityId)?.activityTypeId._id === truckRideActivityTypeId;
            })
            .sort((a, b) => {
                const companyA = a.truckId?.truckCompanyId?._id ?? '';
                const companyB = b.truckId?.truckCompanyId?._id ?? '';

                if (companyA < companyB) return -1;
                if (companyA > companyB) return 1;

                const immatA = a.truckId?.plateNumber ?? '';
                const immatB = b.truckId?.plateNumber ?? '';

                if (immatA < immatB) return -1;
                if (immatA > immatB) return 1;

                const siteA = activitiesMap.get(a.activityId)?.siteId?._id ?? '';
                const siteB = activitiesMap.get(b.activityId)?.siteId?._id ?? '';

                if (siteA < siteB) return -1;
                if (siteA > siteB) return 1;

                return 0;
            });
    };

    const formatTime = (time: string | undefined) => {
        if (time === undefined) return '';
        const date = new Date(time);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <div className="table-container">
            <table id="csv-planning-obj">
                <thead>
                    <tr>
                        <th scope="col" className="w-200px pos-sy left-0 z-idx-4">
                            {capitalizeFirstLetter(t('site'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('codeProC'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('company'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('plateNumber'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('driver'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('type'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('mode'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('departureLoadNature'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('arrivalLoadNature'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('source(c)'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('source(ex)'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('dest(c)'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('dest(ex)'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('departureDump'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('arrivalDump'))}
                        </th>
                        <th scope="col" className="w-300px">
                            {capitalizeFirstLetter(t('transfers'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('departureHour'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('siteHour'))}
                        </th>
                        <th scope="col" className="w-100px">
                            {capitalizeFirstLetter(t('arrivalHour'))}
                        </th>
                        <th scope="col" className="w-200px">
                            {capitalizeFirstLetter(t('observations'))}
                        </th>
                    </tr>
                </thead>
                <tbody id="fu-planning-obj">
                    {sortedDailyTrucksRides().map((truckRide) => {
                        const activity = activities.find((value) => value._id === truckRide?.activityId);
                        const site = activity?.siteId;
                        const conduc = site?.conducId;

                        return (
                            <tr key={truckRide._id} className="row">
                                <td scope="row" className="pos-sy left-0 z-idx-2">
                                    {site?.name}
                                </td>
                                <td>{truckRide.truckId?.codeProC}</td>
                                <td>{truckRide.truckId?.truckCompanyId?.designation}</td>
                                <td>{truckRide.truckId?.plateNumber}</td>
                                <td>
                                    {capitalizeFirstLetter(
                                        activities.find((value) => value._id === truckRide.activityId)?.reportId.userId.firstName
                                    ) +
                                        ' ' +
                                        activities.find((value) => value._id === truckRide.activityId)?.reportId.userId.lastName.toUpperCase()}
                                </td>
                                <td>{truckRide.truckId?.truckTypeId?.designation}</td>
                                <td>{capitalizeFirstLetter(t(getTruckModeInfos(truckRide?.mode)))}</td>
                                <td>{truckRide.departureLoadNatureId?.designation}</td>
                                <td>{truckRide.arrivalLoadNatureId?.designation}</td>
                                <td>{truckRide.departureSiteId?.name || ''}</td>
                                <td>{truckRide.departureQuarryId?.designation || ''}</td>
                                <td>{truckRide.arrivalSiteId?.name || ''}</td>
                                <td>{truckRide.arrivalQuarryId?.designation || ''}</td>
                                <td>{truckRide.departureDumpId?.designation || ''}</td>
                                <td>{truckRide.arrivalDumpId?.designation || ''}</td>
                                <td>
                                    {truckRide.transfers.map((transfer, transferIdx) => (
                                        <div
                                            className="border-radius-5px pad-l-5px fx-c"
                                            style={{
                                                backgroundColor: getMaterialStateColor(transfer.state),
                                                marginTop: transferIdx !== 0 ? 5 : 0,
                                            }}
                                            title={
                                                (transfer.machineId?.designation ||
                                                    transfer.toolId?.designation ||
                                                    `${transfer.rentalName} ${transfer.rentalCompanyId?.designation}`) +
                                                ` ${t(getMaterialStateInfos(transfer.state))} ` +
                                                (transfer.siteId?.name || transfer.quarryId?.designation)
                                            }
                                        >
                                            <div className="one-line">
                                                {transfer.machineId?.designation ||
                                                    transfer.toolId?.designation ||
                                                    `${transfer.rentalName} ${transfer.rentalCompanyId?.designation}`}
                                            </div>
                                            <div className="one-line">{transfer.siteId?.name || transfer.quarryId?.designation}</div>
                                        </div>
                                    ))}
                                </td>
                                <td>{formatTime(truckRide.departureTime)}</td>
                                <td>{formatTime(truckRide.time)}</td>
                                <td>{formatTime(truckRide.arrivalTime)}</td>
                                <td>{truckRide.observations || ''}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TrucksRidesDriversTable;
