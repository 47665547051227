import React from 'react';
import semieImage from '../assets/images/semie.png';
import amplirollImage from '../assets/images/ampliroll.png';
import x4Image from '../assets/images/8x4.png';
import porteCharImage from '../assets/images/porte-char.png';
import unknownTruckImage from '../assets/images/unknowm-truck.png';

interface TruckIconProps {
    type: 'Semie' | 'Ampliroll' | '8x4' | 'Porte-char' | '';
}

const TruckIcon: React.FC<TruckIconProps> = ({ type }) => {
    let imageSrc: string;

    switch (type) {
        case 'Semie':
            imageSrc = semieImage;
            break;
        case 'Ampliroll':
            imageSrc = amplirollImage;
            break;
        case '8x4':
            imageSrc = x4Image;
            break;
        case 'Porte-char':
            imageSrc = porteCharImage;
            break;
        default:
            imageSrc = unknownTruckImage;
            break;
    }
    return <img height={20} title={type} src={imageSrc} alt={`Truck icon ${type}`} style={{padding: 5, paddingLeft: 10}} />;
};

export default TruckIcon;
