import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeEveryFirstWordLetter, capitalizeFirstLetter } from '../utils/capitalize';
import Icon from './Icon';

interface HeaderProps {
    title: string;
    children?: React.ReactNode;
    isSearchable?: boolean;
    onSearch?: (text: string) => void;
    searchValue?: string;
    isDateVisible?: boolean;
    date?: Date;
    onLeftArrowClick?: () => void;
    onRightArrowClick?: () => void;
}

const Header: React.FC<HeaderProps> = ({
    title,
    children,
    isSearchable = true,
    date = new Date(),
    isDateVisible = true,
    onLeftArrowClick = () => {},
    onRightArrowClick = () => {},
}) => {
    const { t } = useTranslation();
    const [universalFilter, setUniversalFilter] = useState('');

    const sameDay = (d1: Date, d2: Date) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };

    useEffect(() => {
        if (!isSearchable) return;
        const inputElement = document.getElementById('fu-classic') as HTMLInputElement;

        if (!inputElement) return;

        if (universalFilter !== '') {
            inputElement.style.borderColor = 'white';
        } else {
            inputElement.style.borderColor = 'gray';
        }

        const tbodyElement = document.getElementById('fu-planning-obj');
        if (tbodyElement) {
            const rows = tbodyElement.querySelectorAll('tr');
            //if (rows.item(0).className === 'row-empty') return;
            rows.forEach((row) => {
                const rowElement = row as HTMLElement;
                if (rowElement.id === 'fu-exclude') return;
                let containsFilter = false;
                rowElement.querySelectorAll('*').forEach((child) => {
                    const childElement = child as HTMLElement;

                    const childText = childElement.textContent?.toLowerCase() || '';
                    const childValue = (childElement.getAttribute('value') || '').toLowerCase();
                    const childTitle = (childElement.getAttribute('title') || '').toLowerCase();

                    if (
                        childText.includes(universalFilter.toLowerCase()) ||
                        childValue.includes(universalFilter.toLowerCase()) ||
                        childTitle.includes(universalFilter.toLowerCase())
                    ) {
                        containsFilter = true;
                    }
                });

                rowElement.style.display = containsFilter ? '' : 'none';
            });
        } else {
            console.log('tbody not found');
        }
    }, [universalFilter]);

    return (
        <div className="fx-r fx-w fx-justify-sb fx-items-c w-100 m-t-10px">
            <div className="fx-r fx-items-c fx-justify-sb fx-w border-radius-5px pad-l-10px pad-r-10px modern-container w-100">
                <h3 className="xxlFont m-t-10px m-b-10px text-white">{title.toUpperCase()}</h3>
                <div className="fx-r fx-items-c fx-justify-sb fx-w ">
                    {isDateVisible && (
                        <>
                            <div className="fx-r fx-items-c fx-justify-sb w-400px">
                                <Icon
                                    name="arrowLeftSharp"
                                    onClick={onLeftArrowClick}
                                    onKeyDown={(event) => {
                                        if (event.ctrlKey && event.key === 'ArrowLeft') onLeftArrowClick();
                                    }}
                                    dark
                                    classes={{icon: 'w-40px-imp h-40px-imp'}}
                                />

                                <div
                                    className={'plan-date fx-r fx-nw fx-justify-c xxlFont ' + (sameDay(date, new Date()) ? 'text-decoration-ul' : '')}
                                    style={{
                                        color: date.getDay() === 6 ? 'orange' : date.getDay() === 0 ? 'crimson' : 'white',
                                    }}
                                >
                                    {capitalizeEveryFirstWordLetter(
                                        date.toLocaleDateString(t('localeDate'), {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })
                                    )}
                                </div>
                                <Icon
                                    name="arrowRightSharp"
                                    onClick={onRightArrowClick}
                                    dark
                                    onKeyDown={(event) => {
                                        console.log(event.key);
                                        console.log(event.ctrlKey);
                                        if (event.ctrlKey && event.key === 'ArrowRight') onRightArrowClick();
                                    }}
                                    classes={{icon: 'w-40px-imp h-40px-imp'}}
                                />
                            </div>
                            <div className="divider" />
                        </>
                    )}
                    {children}
                    {isSearchable && (
                        <div className="fx-r fx-items-c pad-l-15px">
                            <input
                                type="text"
                                value={universalFilter}
                                onChange={(event) => setUniversalFilter(event.target.value)}
                                placeholder={capitalizeFirstLetter(t('search')) + '...'}
                                className="modern-input"
                                id="fu-classic"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
