import React, { useState } from 'react';
import { Modal, Button } from '@mui/material';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import ModalBody from './ModalBody';

interface ModalProps {
    currentDate: Date;
    setCurrentDate: (newDate: string) => void;
    open: boolean;
    handleClose: () => void;
}

const CalendarModal: React.FC<ModalProps> = ({ currentDate, setCurrentDate, open, handleClose }) => {
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const { t } = useTranslation();

    return (
        <ModalBody
            title={t('calendar')}
            closeText={t('cancel')}
            okText={t('validate')}
            open={open}
            handleClose={handleClose}
            handleOk={() => {
                setCurrentDate(selectedDate.toISOString());
            }}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <DateCalendar
                    onChange={(value) => (value ? setSelectedDate(new Date(value?.toISOString())) : null)}
                    value={dayjs(selectedDate)}
                    displayWeekNumber
                />
            </div>
        </ModalBody>
    );
};

export default CalendarModal;
