import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../endpoints';

export interface Tool {
    crudStatus: number;
    _id: string;
    codeProC: string;
    designation: string;
    toolTypeId: ToolType;
}

interface ToolType {
    designation: string;
    _id: string;
}

interface ToolsResponse {
    message: string;
    data: Tool[];
}

export const getAllTools = async (): Promise<ToolsResponse> => {
    try {
        const response: AxiosResponse<ToolsResponse> = await apiClient.get('/tools');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getActiveTools = async (): Promise<ToolsResponse> => {
    try {
        const response: AxiosResponse<ToolsResponse> = await apiClient.get('/tools');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface UpdateToolBody {
    designation?: string;
    codeProC?: string;
    toolTypeId?: string;
    userId?: string;
    crudStatus?: number;
}

export interface ToolResponse {
    message: string;
    data: Tool;
}

export const updateTool = async (toolId: string, data: UpdateToolBody): Promise<ToolResponse> => {
    try {
        const response: AxiosResponse<ToolResponse> = await apiClient.patch('/tools/' + toolId, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export interface CreateToolBody {
    designation: string;
    codeProC: string;
    toolTypeId: string;
}

export const createTool = async (data: CreateToolBody): Promise<ToolResponse> => {
    try {
        const response: AxiosResponse<ToolResponse> = await apiClient.post('/tools', data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
