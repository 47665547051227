import React from 'react';
import { useDrop } from 'react-dnd';

interface DroppableCellProps {
    dropId: { lineId: string }; // Mise à jour ici
    moveCell: (fromLineId: string, fromItemId: string, toLineId: string) => void;
    children?: React.ReactNode;
    itemType: string;
    disabled?: boolean;
}

const DroppableCell: React.FC<DroppableCellProps> = ({
    dropId,
    moveCell,
    children,
    itemType,
    disabled = false,
}) => {
    const [, drop] = useDrop({
        accept: itemType,
        drop: (item: { dragIds: { lineId: string; itemId: string } }) => {
            if (!disabled) {
                const { lineId: fromLineId, itemId: fromItemId } = item.dragIds;
                const { lineId: toLineId } = dropId;
                moveCell(fromLineId, fromItemId, toLineId);
            }
        },
    });

    return (
        <div className="droppable" ref={drop}>
            {children}
        </div>
    );
};

export default DroppableCell;
