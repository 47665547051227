import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { updateDailySubContractor } from '../../services/apiSLTP/activities';
import CreateDailySubContractorModal from '../modals/ManageDailySubContractorModal';
import { createDailySubContractor, deleteDailySubContractor } from '../../services/apiSLTP/planning';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';

interface SubContractorsCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    hiddenPlusIcon?: boolean;
    refetch?: () => void;
}

const SubContractorsCell: React.FC<SubContractorsCellProps> = ({
    activity,
    activityIndex,
    activities,
    disabled = false,
    hiddenPlusIcon = false,
    refetch = () => {},
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const { t } = useTranslation();
    const itemType = 'SUBCONTRACTORS';

    const handleSubContractorsChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailySubContractor(fromItemId, {activityId: toLineId});
            refetch();
        } catch (error) {
            console.error('Error updating daily temp worker:', error);
        }
    };

    const handleDeleteSubContractor = async (id: string, index: number) => {
        try {
            await deleteDailySubContractor(id);
            refetch();
        } catch (error) {
            console.error('Error deleting daily temp worker:', error);
        }
    };

    const handleCreateDailySubContractor = async (dailySubContractorId: string) => {
        const selectedSubContractor = activity.dailySubContractors.find((value) => value._id === dailySubContractorId);
        if (selectedSubContractor) {
            const response = await createDailySubContractor(activity._id, selectedSubContractor.subContractorTypeId._id);
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleSubContractorsChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedId('');
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-200px pad-l-5px">
                        {activity.dailySubContractors.map((subContractor, index) => (
                            <DraggableCell
                                key={`${activityIndex}-${index}`}
                                dragIds={{ itemId: subContractor._id, lineId: activity._id }}
                                index={index}
                                itemType={itemType}
                                onDelete={() => handleDeleteSubContractor(subContractor._id, index)}
                                disabled={disabled}
                                state={subContractor.state}
                                onDuplicate={() => handleCreateDailySubContractor(subContractor._id)}
                                onDoubleClick={() => {
                                    setShowModal(true);
                                    setSelectedId(subContractor._id);
                                }}
                            >
                                <div
                                    title={subContractor.subContractorTypeId.designation}
                                    className="draggable-text"
                                >
                                    {subContractor.subContractorTypeId.designation}
                                </div>
                            </DraggableCell>
                        ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <CreateDailySubContractorModal
                    open={showModal}
                    activity={activity}
                    activityIndex={activityIndex}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    subContractorId={selectedId}
                />
            )}
        </div>
    );
};

export default SubContractorsCell;
