import React, { useState } from 'react';
import DropDown from '../DropDown';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { updateDailyRental } from '../../services/apiSLTP/activities';
import CreateDailyRentalModal from '../modals/ManageDailyRentalModal';
import { createDailyRental, deleteDailyRental } from '../../services/apiSLTP/planning';
import WarningIcon from '@mui/icons-material/Warning';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

interface RentalsCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    hiddenPlusIcon?: boolean;
    refetch?: () => void;
}

const RentalsCell: React.FC<RentalsCellProps> = ({
    activity,
    activityIndex,
    activities,
    disabled = false,
    hiddenPlusIcon = false,
    refetch = () => {},
}) => {
    const [selectedId, setSelectedId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const itemType = 'RENTALS';
    const { t } = useTranslation();

    const isDuplicateRental = (rentalId: string): boolean => {
        let duplicateCount = 0;
        for (const activity of activities) {
            for (const rental of activity.dailyRentals) {
                if (rental.designation === rentalId) {
                    duplicateCount++;
                    if (duplicateCount > 1) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const handleRentalsChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailyRental(fromItemId, {activityId: toLineId});
            refetch();
        } catch (error) {
            console.error('Error updating daily rental:', error);
        }
    };

    const handleDeleteRental = async (id: string, index: number) => {
        try {
            await deleteDailyRental(id);
            refetch();
        } catch (error) {
            console.error('Error deleting daily rental:', error);
        }
    };

    const handleCreateDailyRental = async (dailyRentalId: string) => {
        const selectedRental = activity.dailyRentals.find((value) => value._id === dailyRentalId);
        if (selectedRental) {
            const response = await createDailyRental(activity._id, selectedRental.rentalTypeId._id, selectedRental.designation, selectedRental.state);
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleRentalsChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedId('');
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-250px pad-l-5px">
                        {activity.dailyRentals.map((rental, index) => (
                            <DraggableCell
                                key={`${activityIndex}-${index}`} // Utilisez une clé unique
                                dragIds={{ itemId: rental._id, lineId: activity._id }}
                                index={index}
                                itemType={itemType}
                                onDelete={() => handleDeleteRental(rental._id, index)}
                                disabled={disabled}
                                state={rental.state}
                                warning={isDuplicateRental(rental.designation)}
                                onDuplicate={() => handleCreateDailyRental(rental._id)}
                                onDoubleClick={() => {
                                    setShowModal(true);
                                    setSelectedId(rental._id);
                                }}
                            >
                                <div title={`${rental.designation} - ${rental.rentalTypeId.designation}`} className="draggable-text">
                                    {rental.designation} - {rental.rentalTypeId.designation}
                                </div>
                            </DraggableCell>
                        ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <CreateDailyRentalModal
                    open={showModal}
                    activity={activity}
                    activityIndex={activityIndex}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    rentalId={selectedId}
                />
            )}
        </div>
    );
};

export default RentalsCell;
