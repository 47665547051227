import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { updateDailyTempWorker } from '../../services/apiSLTP/activities';
import CreateDailyTempWorkerModal from '../modals/ManageDailyTempWorkerModal';
import { createDailyTempWorker, deleteDailyTempWorker } from '../../services/apiSLTP/planning';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import Icon from '../Icon';

interface TempWorkersCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    hiddenPlusIcon?: boolean;
    refetch?: () => void;
}

const TempWorkersCell: React.FC<TempWorkersCellProps> = ({
    activity,
    activityIndex,
    activities,
    disabled = false,
    hiddenPlusIcon = false,
    refetch = () => {},
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const { t } = useTranslation();
    const itemType = 'TEMPWORKERS';

    const handleTempWorkersChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailyTempWorker(fromItemId, {activityId: toLineId});
            refetch();
        } catch (error) {
            console.error('Error updating daily temp worker:', error);
        }
    };

    const handleDeleteTempWorker = async (id: string, index: number) => {
        try {
            await deleteDailyTempWorker(id);
            refetch();
        } catch (error) {
            console.error('Error deleting daily temp worker:', error);
        }
    };

    const handleCreateDailyTempWorker = async (dailyTempWorkerId: string) => {
        const selectedTempWorker = activity.dailyTempWorkers.find((value) => value._id === dailyTempWorkerId);
        if (selectedTempWorker) {
            const response = await createDailyTempWorker(activity._id, selectedTempWorker.tempWorkerTypeId._id, selectedTempWorker.durationDay);
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleTempWorkersChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedId('');
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-200px pad-l-5px">
                        {activity.dailyTempWorkers.map((tempWorker, index) => (
                            <DraggableCell
                                key={`${activityIndex}-${index}`}
                                dragIds={{ itemId: tempWorker._id, lineId: activity._id }}
                                index={index}
                                itemType={itemType}
                                onDelete={() => handleDeleteTempWorker(tempWorker._id, index)}
                                disabled={disabled}
                                state={tempWorker.state}
                                onDuplicate={() => handleCreateDailyTempWorker(tempWorker._id)}
                                onDoubleClick={() => {
                                    setShowModal(true);
                                    setSelectedId(tempWorker._id);
                                }}
                            >
                                <div
                                    title={tempWorker.durationDay + t('dayAbreviation') + ' - ' + tempWorker.tempWorkerTypeId.designation}
                                    className="draggable-text"
                                >
                                    {tempWorker.durationDay + t('dayAbreviation')} - {tempWorker.tempWorkerTypeId.designation}
                                </div>
                            </DraggableCell>
                        ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <CreateDailyTempWorkerModal
                    open={showModal}
                    activity={activity}
                    activityIndex={activityIndex}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    tempWorkerId={selectedId}
                />
            )}
        </div>
    );
};

export default TempWorkersCell;
