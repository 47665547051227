import React, { useState } from 'react';
import { DataItem } from '../../services/apiSLTP/activities';
import DroppableCell from '../DroppableCell';
import DraggableCell from '../DraggableCell';
import { updateDailyTool } from '../../services/apiSLTP/activities';
import CreateDailyToolModal from '../modals/ManageDailyToolModal';
import { createDailyTool, deleteDailyTool } from '../../services/apiSLTP/planning';
import WarningIcon from '@mui/icons-material/Warning';
import { capitalizeFirstLetter } from '../../utils/capitalize';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

interface ToolsCellProps {
    activity: DataItem;
    activities: DataItem[];
    activityIndex: number;
    disabled?: boolean;
    hiddenPlusIcon?: boolean;
    refetch?: () => void;
}

const ToolsCell: React.FC<ToolsCellProps> = ({
    activity,
    activityIndex,
    activities,
    disabled = false,
    hiddenPlusIcon = false,
    refetch = () => {},
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const itemType = 'TOOLS';
    const { t } = useTranslation();

    const isDuplicateTool = (toolId: string): boolean => {
        let duplicateCount = 0;
        for (const act of activities) {
            for (const tool of act.dailyTools) {
                if (tool.toolId?._id === toolId) {
                    duplicateCount++;
                    if (duplicateCount > 1) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const handleToolsChange = async (fromLineId: string, fromItemId: string, toLineId: string) => {
        if (fromLineId === toLineId) return;
        try {
            await updateDailyTool(fromItemId, {activityId: toLineId});
            refetch();
        } catch (error) {
            console.error('Error updating daily tool:', error);
        }
    };

    const handleDeleteTool = async (id: string, index: number) => {
        try {
            await deleteDailyTool(id);
            refetch();
        } catch (error) {
            console.error('Error deleting daily tool:', error);
        }
    };

    const handleCreateDailyTool = async (dailyToolId: string) => {
        const selectedTool = activity.dailyTools.find((value) => value._id === dailyToolId);
        if (selectedTool) {
            const response = await createDailyTool(activity._id, selectedTool.toolId._id, selectedTool.state);
            refetch();
        }
    };

    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <DroppableCell
                dropId={{ lineId: activity._id }}
                moveCell={(fromLineId, fromItemId, toLineId) => handleToolsChange(fromLineId, fromItemId, toLineId)}
                itemType={itemType}
                disabled={disabled}
            >
                <div className="fx-r">
                    <div>
                        {!hiddenPlusIcon && (
                            <Icon
                                name="addCircle"
                                onClick={() => {
                                    setShowModal(true);
                                    setSelectedId('');
                                }}
                                disabled={disabled}
                                title={capitalizeFirstLetter(t('add'))}
                            />
                        )}
                    </div>
                    <div className="w-250px pad-l-5px">
                        {activity.dailyTools.map((tool, index) => (
                            <DraggableCell
                                key={`${activityIndex}-${index}`}
                                dragIds={{ itemId: tool._id, lineId: activity._id }}
                                index={index}
                                itemType={itemType}
                                onDelete={() => handleDeleteTool(tool._id, index)}
                                disabled={disabled}
                                state={tool.state}
                                warning={isDuplicateTool(tool.toolId._id)}
                                onDuplicate={() => handleCreateDailyTool(tool._id)}
                                onDoubleClick={() => {
                                    setShowModal(true);
                                    setSelectedId(tool._id);
                                }}
                            >
                                <div title={tool.toolId.designation} className="draggable-text">
                                    {tool.toolId.designation}
                                </div>
                            </DraggableCell>
                        ))}
                    </div>
                </div>
            </DroppableCell>
            {showModal && (
                <CreateDailyToolModal
                    open={showModal}
                    activity={activity}
                    activityIndex={activityIndex}
                    handleClose={() => setShowModal(false)}
                    refetch={refetch}
                    toolId={selectedId}
                />
            )}
        </div>
    );
};

export default ToolsCell;
